import React from "react";
import { simpleData } from "../../utils/data";
import { simpleInterface } from "../../utils/interfaces";
import "./simplePlatform.scss";

function SimplePlatfrom(): JSX.Element {
  return (
    <section
      className="simple overflow-hidden bg-lightBg lg:py-8"
      id="simple-platform"
    >
      <div className="container mx-auto mt-5 lg:mt-9 pt-4 pb-5 flex flex-col items-baseline md:flex md:flex-col lg:flex lg:flex-col lg:items-start lg:space-y-8 lg:pt-0 lg:pb-5  md:items-center">
        <h2 className="text-regularBlack pb-6 px-5 lg:px-0 lg:text-5xl lg:leading-9 font-bold font-CircularStd_Bold lg:flex lg:flex-col space-y-4 lg:w-full lg:pb-7">
          <span>Simple platform for all your investments</span>
          <span>& Smooth Transactions</span>
        </h2>

        <div className="grid grid-cols-1 gap-y-8 gap-x-8 md:grid-cols-2 lg:grid-cols-4 pt-5 md:gap-x-12 md:gap-y-12 lg:gap-x-8 pb-12">
          {simpleData.map((i: simpleInterface, idx: any): JSX.Element => {
            return (
              <div
                key={i.id + idx}
                className="flex flex-col px-5 md:px-0 lg:px-0 space-y-5 items-start"
              >
                <div className="lightt flex flex-row items-center justify-center text-center">
                  <img
                    src={i.img}
                    alt={i.title}
                    className=""
                    // style={{ height: "60px", width: "60px" }}
                  />
                </div>

                <h6 className="text-regularBlue font-normal capitalize font-CircularStd_Medium text-2xl leading-normal">
                  {i.title}
                </h6>

                <p className="text-regularBlack font-CircularStd_Medium font-medium text-lg leading-6">
                  {i.content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default SimplePlatfrom;
