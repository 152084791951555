import React from "react";

function Availability(): JSX.Element {
  return (
    <section className="availability overflow-hidden bg-lightBg">
      <div className="container mx-auto mt-5 lg:mt-9 pt-4 pb-5 flex flex-col items-baseline md:flex md:flex-col lg:flex lg:flex-col lg:items-start lg:space-y-12 lg:pt-0 lg:pb-5  md:items-center">
        <h2 className="text-regularBlack text-center pb-6 px-5 lg:px-0 lg:text-5xl lg:leading-9 font-bold font-CircularStd_Bold lg:flex lg:flex-col space-y-4 lg:w-full lg:pb-7 lg:pt-14">
          Available Anywhere & for Everyone
        </h2>

        <div className="mx-auto text-center lg:pt-12">
          <img
            src={require("../../assets/images/OBJECTS.png")}
            alt="maps"
            className="px-3 lg:px-0"
          />
        </div>
      </div>
    </section>
  );
}

export default Availability;
