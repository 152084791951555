import * as Yup from "yup";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const EmailRegexp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const requiredMsg = "This field is required";

export const Schema = Yup.object().shape({
  firstName: Yup.string().required(requiredMsg),
  lastName: Yup.string().required(requiredMsg),
  email: Yup.string().email("Invalid email").required(requiredMsg),

  service: Yup.string().required("Please select a role"),
  message: Yup.string().notRequired(),
});
