import React from "react";
import Header from "../Header/Header2";
import Hero from "../Hero/Hero";
import "./combine.scss";

function Combine(): JSX.Element {
  return (
    <section
      className="bg-regularBlue relative text-regularWhite lg:bg-regularBlue md:bg-regularBlue h-auto flex flex-col space-y-4 overflow-hidden pb-16 pt-2 combine"
      id="combine"
    >
      <Header />
      <Hero />
    </section>
  );
}

export default Combine;
