import React from "react";
import Availability from "./Availability/Availability";
import Combine from "./Combine/Combine";
import EducationPlatform from "./EducationPlatform/EducationPlatform";
import Footer from "./Footer/Footer";
import GetStarted from "./GetStarted/GetStarted";
import MultiAssets from "./MultiAssets/MultiAssets";
import RealTimeTrading from "./RealTimeTrading/RealTimeTrading";
import Security from "./Security/Security";
import SimplePlatfrom from "./SimplePlatform/SimplePlatfrom";

function Layout(): JSX.Element {
  return (
    <React.Fragment>
      <Combine />
      <MultiAssets />
      <SimplePlatfrom />
      <RealTimeTrading />
      <EducationPlatform />
      <Security />
      <Availability />
      <GetStarted />
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
