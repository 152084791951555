import React from "react";
import HeroImage from "../../assets/images/trade.png";
import "./multiAssets.scss";

function MultiAssets(): JSX.Element {
  return (
    <section
      className="about overflow-hidden bg-regularWhite pt-10 pb-10 mb-10"
      id="about"
    >
      <div className="container mx-auto mt-5 lg:mt-28 pt-10 pb-5 flex flex-col items-baseline md:flex md:flex-col lg:flex lg:flex-row lg:space-x-12 lg:pt-0 lg:pb-5  md:items-center">
        {/* PUPILS */}
        <div
          className="lg:w-1/2 pt"
          data-aos="fade-down-right"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <img src={HeroImage} className="py-4 px-3 lg:px-0" alt="" />
        </div>

        {/* ABOUT */}
        <div
          className="w-full lg:w-1/2 flex flex-col items-center space-y-4 pt-8 lg:text-left lg:items-start"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <h2 className="text-regularBlack pb-4 lg:text-5xl lg:leading-9 font-bold font-CircularStd_Bold lg:w-full">
            Foreign Assets
          </h2>

          <p className="w-4/5 text-justify lg:w-4/5 font-InterRegular font-normal text-base text-regularGrey lg:text-sm lg:leading-7 lg:text-left">
            You can invest in over 300+ international Assets with your Naira
            debit cards, it is simple, fast and convenient.
          </p>

          <button
            className="cursor-pointer border border-solid text-regularBlue font-CircularStd_Medium font-medium text-lg w-44 text-center px-8 py-4 rounded-[50px] border-regularBlue light-bg"
            type={"button"}
          >
            Get Started
          </button>
        </div>
      </div>

      {/* <Solution /> */}
    </section>
  );
}

export default MultiAssets;
