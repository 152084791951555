import React from "react";
import { Link } from "react-router-dom";
import OmneyLogo from "../../assets/svgs/logo.svg";
import { grid1 } from "../../utils/data";
import { gridInterface } from "../../utils/interfaces";

function Footer(): JSX.Element {
  const d = new Date();
  const year = d.getFullYear();
  return (
    <footer className="footer overflow-hidden bg-regularBlue text-regularWhite">
      <div className="container mx-auto flex flex-col pt-16 pb-16">
        <div className="flex flex-col-reverse px-6 lg:px-0 md:flex md:flex-row lg:flex lg:flex-row items-start justify-between pb-5">
          {/* left */}
          <div className="flex flex-col lg:space-y-0 items-start lg:px-0">
            <Link to={"/"} className="">
              <img
                src={OmneyLogo}
                alt="secure app logo"
                className="pt-5 pb-5 md:pt-0 md:pl-0 lg:pt-0 lg:pl-0"
              />
            </Link>

            <div className="flex flex-col">
              <p className="mb-2 font-CircularStd_Bold font-bold text-regularWhite text-lg leading-6">
                Nigeria
              </p>
              <span className="flex flex-col w-full space-y-2">
                <span className="text-left font-CircularStd_Book font-normal text-regularWhite text-base leading-6">
                  4th Floor, First City Plaza,
                </span>
                <span className="font-CircularStd_Book font-normal text-regularWhite text-base leading-6">
                  Marina P.O.Box 9117,
                </span>
                <span className="font-CircularStd_Book font-normal text-regularWhite text-base leading-6">
                  Lagos State, Nigeria
                </span>
              </span>
            </div>

            {/* <div className="flex flex-col">
              <p className="mb-2 font-CircularStd_Bold font-bold text-regularWhite text-lg leading-6">
                United Kingdom
              </p>
              <span className="w-3/4 text-left mb-5 font-CircularStd_Book font-normal text-regularWhite text-base leading-6">
                No 2, Angle-View Estate, Chevron Alternative Lekki, Lagos,
                Nigeria
              </span>
            </div> */}
          </div>

          {/* right */}
          <div className="flex flex-row">
            <ul className="flex flex-row space-x-4">
              {grid1.map((i: gridInterface, idx: any) => {
                return (
                  <li className="py-2" key={i.id + idx}>
                    <Link
                      to={`${i.link}`}
                      className="no-underline font-CircularStd_Medium font-medium leading-5 text-sm"
                    >
                      {i.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* GRID LINKS WERE HERE */}
        <hr />
        <div className="pt-5">
          <div className="flex flex-col space-y-3 lg:space-y-0 items-start  px-6 lg:px-0 lg:flex-row lg:items-center justify-between">
            {/* <Link to={"/"}>
              <img
                src={OmneyLogo}
                alt="secure app logo"
                className="pt-5 md:pt-0 md:pl-0 lg:pt-0 lg:pl-0"
              />
            </Link> */}

            <p className="font-CircularStd_Book font-normal text-base leading-6 tracking-normal text-regularWhite">{`© ${year} All rights reserved.`}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
