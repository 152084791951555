import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header2";
import "./privacy.scss";

function Privacy(): JSX.Element {
  const { pathname }: any = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main className="overflow-hidden">
      <Header colorTheme={"dark"} />
      <section className="overflow-hidden">
        <div className="container mx-auto flex flex-col items-center justify-center pt-16">
          <article className="space-y-3 w-5/6 md:w-4/5 lg:w-4/5 pb-14 mb-20">
            <div className="flex flex-col space-y-4 items-start justify-start pb-12">
              <h4 className="font-CircularStd_Bold  font-bold text-black text-3xl md:text-3xl lg:text-7xl">
                Privacy Policy
              </h4>
              <span className="font-CircularStd_Book font-medium text-2xl text-black text-left">
                Last updated: November 01, 2022
              </span>
            </div>

            <div className="flex flex-col w-4/5 md:w-5/6 lg:w-5/6 items-start justify-start space-y-5 py-7">
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                This privacy statement sets out the privacy policy of Omney
                Application (Omney) which provides a portal, or gateway, to the
                financial services offered by CSL Stockbrokers Limited ('CSLS'),
                and its Affiliates.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                CSL Stockbrokers Limited is a private limited company registered
                in the Federal Republic of Nigeria under RC: No. 27567 with
                head-office at First City Plaza, 44 Marina Road, Lagos State,
                Nigeria.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                CSL Stockbrokers Limited hosts the Omney and provides technical
                support, access and links to the local sites of CSLS and its
                Affiliates. www.cslstockbrokers.com/, as a site, offer financial
                services or products. Financial services and products may only
                be obtained by accessing the website of the relevant operating
                company within CSLS. For more information on how to use this
                website, please see the Terms and Conditions of Use.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                CSL Stockbrokers Limited and its Affiliates provides financial
                products and services located in 2 countries and over 2 states
                of the Federal Republic of Nigeria. Privacy and personal data
                protection principles vary from one country to another. When you
                access or link to a Local Site, please read the privacy
                statement issued by the Local Site to determine the policies
                that apply to information or data maintained by the Local Site.
              </span>
            </div>

            <div className="flex flex-col w-4/5 md:w-5/6 lg:w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-lg md:text-2xl lg:text-2xl">
                Cookies
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                This website, along with most other major websites, use cookies.
                Cookies are pieces of information that a website transfers to
                the cookie file on your computer’s hard disk. Cookies enable
                users to navigate around the website and (where appropriate)
                enable us to tailor the content to fit the needs of visitors who
                have accessed the site.
              </span>

              <p className="text-black text-left font-CircularStd_Bold text-lg md:text-lg lg:text-lg">
                Omney uses two types of cookies on this website:
              </p>

              <ul className="list-disc px-6">
                <li className="">
                  <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6 ">
                    Session cookies, which are temporary cookies that remain in
                    the cookie file of your computer until you close your
                    browser (at which point they are deleted).
                  </span>
                </li>
                <li>
                  <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                    Persistent or stored cookies that remain permanently on the
                    cookie file of your computer.
                  </span>
                </li>
              </ul>

              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Cookies cannot look into your computer and obtain information
                about you or your family or read any material kept on your hard
                drive and, unless you have logged onto an authenticated page,
                cookies cannot be used to identify who you are.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Cookies cannot be used by anyone else who has access to the
                computer to find out anything about you, other than the fact
                that someone using the computer has visited a certain website.
                Cookies do not in any way compromise the security of your
                computer.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Cookies will not be used to contact you for marketing purposes
                other than by means of advertisements offered within this
                website.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Cookies may be used to record details of pages relating to
                particular products and services that you have visited on this
                website. This is to provide firstcitygroup.com with generic
                usage statistics to allow the company to improve this website
                and to provide you with information that may interest you.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                The web browsers of most computers are initially set up to
                accept cookies. If you prefer, you can set your web browser to
                disable cookies or to inform you when a website is attempting to
                add a cookie. You can also delete cookies that have previously
                been added to your computer’s cookie file.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                You can set your browser to disable persistent cookies and/or
                session cookies but if you disable session cookies, although you
                will be able to view this website’s unsecured pages, you may not
                be able to log onto any authenticated pages.
              </span>
            </div>
          </article>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default Privacy;
