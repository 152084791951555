import Trade from "../assets/images/transfer.svg";
import User from "../assets/images/user.svg";
import Wallet from "../assets/images/wallet.svg";
import Withdraw from "../assets/images/withdraw.svg";
import {
  gridInterface,
  questionsInterface,
  simpleInterface,
} from "./interfaces";

export const simpleData: simpleInterface[] = [
  {
    id: 1,
    title: "Open Account",
    content:
      "Seamlessly create account without stress, easy funding of wallet and smooth withdrawals",
    img: User,
  },
  {
    id: 2,
    title: "Trade Assests",
    content:
      "Enter your orders with our intuitively designed trade order ticket",
    img: Wallet,
  },
  {
    id: 3,
    title: "Watchlist",
    content:
      "Set alerts and track the movement in stocks you are interested in",
    img: Trade,
  },
  {
    id: 4,
    title: "Support",
    content:
      "24/7 support from trading, technology and client-experience specialists",
    img: Withdraw,
  },
];

export const grid1: gridInterface[] = [
  { id: 1, title: "Contact Us", link: "/contact-us" },
  { id: 2, title: "Privacy Policy", link: "/privacy" },
  { id: 3, title: "Terms and Conditions", link: "/terms-and-conditions" },
];

export const grid2: gridInterface[] = [
  { id: 1, title: "Company", link: "/" },
  { id: 2, title: "About us", link: "/" },
  { id: 3, title: "Careers", link: "/" },
  { id: 4, title: "Press", link: "/" },
  { id: 5, title: "News", link: "/" },
  { id: 6, title: "Media kit", link: "/" },
  { id: 7, title: "Contact", link: "/contact-us" },
];

export const grid3: gridInterface[] = [
  { id: 1, title: "Product", link: "/" },
  { id: 2, title: "Overview", link: "/" },
  { id: 3, title: "Features", link: "/" },
  { id: 4, title: "Solutions", link: "/" },
  { id: 5, title: "Tutorials", link: "/" },
  { id: 6, title: "Pricing", link: "/" },
  { id: 7, title: "Releases", link: "/" },
];

export const grid4: gridInterface[] = [
  { id: 1, title: "Legal", link: "/" },
  { id: 2, title: "Terms", link: "/terms-and-conditions" },
  { id: 3, title: "Privacy", link: "/privacy" },
  { id: 4, title: "Cookies", link: "/" },
  { id: 5, title: "Licenses", link: "/" },
  { id: 6, title: "Settings", link: "/" },
  { id: 7, title: "Contact", link: "/" },
];

export const question_and_answers: questionsInterface[] = [
  {
    id: 1,
    question: "Q. Interpretations and Definitions",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas duis gravida tristique sed volutpat porttitor interdum praesent ornare. Cursus arcu a orci in fames nunc. Nisl cras nulla at posuere sed. Semper libero, molestie lorem est eu tristique. Nulla nunc, semper eget augue leo tempor.",
  },
  {
    id: 2,
    question: "Q. Interpretations and Definitions",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas duis gravida tristique sed volutpat porttitor interdum praesent ornare. Cursus arcu a orci in fames nunc. Nisl cras nulla at posuere sed. Semper libero, molestie lorem est eu tristique. Nulla nunc, semper eget augue leo tempor.",
  },
  {
    id: 3,
    question: "Q. Interpretations and Definitions",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas duis gravida tristique sed volutpat porttitor interdum praesent ornare. Cursus arcu a orci in fames nunc. Nisl cras nulla at posuere sed. Semper libero, molestie lorem est eu tristique. Nulla nunc, semper eget augue leo tempor.",
  },
  {
    id: 4,
    question: "Q. Interpretations and Definitions",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas duis gravida tristique sed volutpat porttitor interdum praesent ornare. Cursus arcu a orci in fames nunc. Nisl cras nulla at posuere sed. Semper libero, molestie lorem est eu tristique. Nulla nunc, semper eget augue leo tempor.",
  },
  {
    id: 5,
    question: "Q. Interpretations and Definitions",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas duis gravida tristique sed volutpat porttitor interdum praesent ornare. Cursus arcu a orci in fames nunc. Nisl cras nulla at posuere sed. Semper libero, molestie lorem est eu tristique. Nulla nunc, semper eget augue leo tempor.",
  },
];
