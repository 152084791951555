import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header2";
import { Schema } from "../../utils/validator";
import "./contactus.scss";

function ContactUs(): JSX.Element {
  const { pathname }: any = useLocation();
  const [phone, setPhone] = useState<any>("");
  const inputStyle: any = {
    border: "1px solid #D0D5DD",
    paddingTop: "27px",
    paddingBottom: "27px",
    width: "100%",
    borderRadius: "8px",
    paddingLeft: "50px",
    paddingRight: "50px",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  };
  const formRef: any = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main className="overflow-hidden">
      <Header colorTheme={"dark"} />
      <section className="contact-us">
        <div className="container mx-auto flex flex-col lg:space-y-5 py-12 pb-24">
          <div className="flex flex-col px-6 lg:space-y-5 lg:px-0 lg:w-4/5 pt-7 md:pt-12 lg:pt-12">
            <h2 className="font-CircularStd_Bold font-bold text-black text-3xl md:text-3xl lg:text-7xl">
              Need help? Get in touch
            </h2>
            <p className="lg:w-4/5 font-CircularStd_Medium font-medium text-regularBlack text-base tracking-normal opacity-100">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas
              duis gravida tristique sed volutpat porttitor interdum praesent
              ornare. Cursus arcu a orci in fames nunc. Nisl cras nulla at
              posuere sed. Semper libero, molestie{" "}
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 pt-7  lg:pt-10">
            <div className="flex flex-col space-y-4 w-full lg:w-2/3 px-6 lg:px-0">
              <div className="flex flex-col rounded-[11px] space-y-3 bg-lightBg justify-center items-center py-5">
                <img
                  src={require("../../assets/images/location.png")}
                  className=""
                  alt="location"
                  style={{
                    width: "23px",
                    height: "23px",
                    objectFit: "contain",
                  }}
                />

                <p className="font-CircularStd_Bold font-bold text-regularBlack text-2xl">
                  Location
                </p>

                <ul>
                  <li className="text-center font-CircularStd_Medium font-medium text-base">
                    8502 Preston Rd. Inglewood,
                  </li>
                  <li className="text-center font-CircularStd_Medium font-medium text-base">
                    Maine 98380, USA
                  </li>
                </ul>
              </div>

              <div className="flex flex-col rounded-[11px] space-y-3 bg-lightBg justify-center items-center py-5">
                <img
                  src={require("../../assets/images/phone.png")}
                  className=""
                  alt="location"
                  style={{
                    width: "23px",
                    height: "23px",
                    objectFit: "contain",
                  }}
                />

                <p className="font-CircularStd_Bold font-bold text-regularBlack text-2xl">
                  Call Us
                </p>

                <ul>
                  <li className="text-center font-CircularStd_Medium font-medium text-base">
                    (316) 555-0116
                  </li>
                  <li className="text-center font-CircularStd_Medium font-medium text-base">
                    (316) 555-0117
                  </li>
                </ul>
              </div>

              <div className="flex flex-col rounded-[11px] space-y-3 bg-lightBg justify-center items-center py-5">
                <img
                  src={require("../../assets/images/mail.png")}
                  className=""
                  alt="location"
                  style={{
                    width: "23px",
                    height: "23px",
                    objectFit: "contain",
                  }}
                />

                <p className="font-CircularStd_Bold font-bold text-regularBlack text-2xl">
                  Email Us
                </p>

                <ul>
                  <li className="text-center font-CircularStd_Medium font-medium text-base">
                    contact@example.com
                  </li>
                  <li className="text-center font-CircularStd_Medium font-medium text-base">
                    support@example.com
                  </li>
                </ul>
              </div>
            </div>

            {/* CONTACT FORM  */}
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                service: "",
                message: "",
              }}
              validationSchema={Schema}
              onSubmit={() => {}}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                handleReset,
              }) => {
                return (
                  <Form
                    className="flex flex-col space-y-3"
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                    role={"form"}
                    ref={formRef}
                  >
                    <div className="grid grid-cols-1 px-6 lg:px-0 md:grid-cols-2  lg:grid-cols-2 gap-x-4 lg:gap-x-0">
                      <div className="flex flex-col space-y-2 lg:pr-3">
                        <label
                          htmlFor="firstName"
                          className="py-2 font-CircularStd_Medium font-medium text-regularLabel text-sm tracking-normal opacity-100 lg:py-0 md:py-0"
                        >
                          First Name
                        </label>
                        <input
                          className="border border-solid border-regularBorder rounded-lg py-4 px-4 shadow-none outline-none font-CircularStd_Book font-normal text-regularInput text-base tracking-normal opacity-100"
                          value={values.firstName}
                          name="firstName"
                          // id="firstName"
                          placeholder="First Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={"text"}
                        />
                      </div>

                      <div className="flex flex-col space-y-2 lg:pl-3">
                        <label
                          htmlFor="lastName"
                          className="py-2 font-CircularStd_Medium font-medium text-regularLabel text-sm tracking-normal opacity-100 lg:py-0 md:py-0"
                        >
                          Last Name
                        </label>
                        <input
                          className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 font-CircularStd_Book font-normal text-regularInput text-base tracking-normal opacity-100"
                          value={values.lastName}
                          name="lastName"
                          // id="lastName"
                          placeholder="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={"text"}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col space-y-2 px-6 lg:px-0 lg:py-3">
                      <label
                        htmlFor="email"
                        className="py-2 font-CircularStd_Medium font-medium text-regularLabel text-sm tracking-normal opacity-100 lg:py-0 md:py-0"
                      >
                        Your email
                      </label>
                      <input
                        className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 font-CircularStd_Book font-normal text-regularInput text-base tracking-normal opacity-100"
                        value={values.email}
                        name="email"
                        // id="email"
                        placeholder="you@company.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type={"email"}
                      />
                    </div>

                    <div className="flex flex-col space-y-2 px-6 lg:px-0 lg:py-3">
                      <label
                        htmlFor="service"
                        className="py-2 font-CircularStd_Medium font-medium text-regularLabel text-sm tracking-normal opacity-100 lg:py-0 md:py-0"
                      >
                        What kind of service are you looking for?
                      </label>
                      <Field
                        className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 Select bg-regularWhite font-CircularStd_Book font-normal text-regularInput text-base tracking-normal opacity-100"
                        name="service"
                        as="select"
                      >
                        <option value={""}>Select a Subject</option>
                        <option value={"buy"}>Buy</option>
                        <option value={"sell"}>Sell</option>
                      </Field>
                    </div>

                    <div className="flex flex-col space-y-2 px-6 lg:px-0 lg:py-3">
                      <label
                        htmlFor="phoneNumber"
                        className="py-2 font-CircularStd_Medium font-medium text-regularLabel text-sm tracking-normal opacity-100 lg:py-0 md:py-0 "
                      >
                        Phone number
                      </label>

                      <>
                        <PhoneInput
                          enableAreaCodes={true}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          onChange={(e) => setPhone(e)}
                          value={phone}
                          autoFormat
                          placeholder="+1 (555) 000-0000"
                          enableSearch
                          inputStyle={{ ...inputStyle }}
                          inputClass={"inputClass"}
                          containerClass={"inputClass"}
                        />
                      </>
                    </div>

                    <div className="flex flex-col space-y-2 px-6 lg:px-0 lg:py-3">
                      <label
                        htmlFor="message"
                        className="py-2 font-CircularStd_Medium font-medium text-regularLabel text-sm tracking-normal opacity-100 lg:py-0 md:py-0"
                      >
                        Message
                      </label>
                      <textarea
                        className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 h-20 md:h-36 lg:h-36 font-CircularStd_Book font-normal text-regularInput text-base tracking-normal opacity-100"
                        value={values.message}
                        name="message"
                        // id="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>

                    <button
                      className="bg-regularBlue border border-solid border-regularBlue text-center flex flex-row items-center justify-center h-16 rounded-[50px] font-CircularStd_Medium font-medium text-base text-regularWhite mx-6 lg:mx-0"
                      type={"submit"}
                    >
                      Sign Up
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default ContactUs;
