import React, { useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { question_and_answers } from "../../utils/data";
import { questionsInterface } from "../../utils/interfaces";
import "./faq.scss";

function FAQ(): JSX.Element {
  const [selected, setSelected] = useState<any>(null);

  const toggle = (i: any): void => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <main className="overflow-hidden">
      <Header colorTheme={"dark"} />
      <section className="overflow-hidden">
        <div className="container mx-auto flex flex-col lg:space-y-5 py-12 pb-24">
          <div className="flex flex-col px-6 lg:space-y-5 lg:px-0 lg:w-4/5 pt-7 md:pt-12 lg:pt-12">
            <h2 className="font-CircularStd_Bold font-bold text-black text-3xl md:text-3xl lg:text-7xl">
              Frequently Asked Questions
            </h2>
            <p className="lg:w-11/12 font-CircularStd_Medium font-medium text-regularBlack text-base tracking-normal opacity-100">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas
              duis gravida tristique sed volutpat porttitor interdum praesent
              ornare. Cursus arcu a orci in fames nunc. Nisl cras nulla at
              posuere sed. Semper libero, molestie
            </p>
          </div>

          {/* ACCORDION GOES HERE */}
          <div className="pt-7 px-4 lg:px-0">
            <div className="accordion pt-6 lg:w-4/5">
              {question_and_answers.map(
                (i: questionsInterface, idx: any): JSX.Element => {
                  return (
                    <div
                      className="accordion-item bg-regularWhite px-3 mb-2 py-3"
                      key={i.id + idx}
                    >
                      <div
                        className="accordion-title pb-5 flex flex-row items-center justify-between cursor-pointer"
                        onClick={(): void => toggle(idx)}
                      >
                        <h4 className="font-CircularStd_Bold font-bold text-regularBlack text-2xl leading-7">
                          {i.question}
                        </h4>
                        {selected === idx ? (
                          <AiOutlineMinus
                            className="cursor-pointer"
                            color="#000000"
                          />
                        ) : (
                          <BsPlusLg
                            className="cursor-pointer"
                            color="#000000"
                          />
                        )}
                      </div>
                      {selected === idx && (
                        <div
                          className={`animate-shake2 accordion-content pb-5 font-CircularStd_Book font-normal text-base leading-6 pt-4 text-regularBlack overflow-hidden`}
                        >
                          {i.answer}
                        </div>
                      )}
                      <hr />
                    </div>
                  );
                }
              )}
            </div>
          </div>

          <div className="bg-lightBlue h-auto rounded-[20px] md:w-full w-[85%] mx-auto lg:mx-0 md:mx-0 lg:w-full pt-12 mtop pb-12 flex flex-row items-center justify-center">
            <div className="py-5 flex flex-col items-center justify-center space-y-6">
              <h4 className="font-CircularStd_Bold font-bold text-black text-3xl md:text-3xl lg:text-5xl">
                Still need answers?
              </h4>
              <p className="w-1/2 text-center font-CircularStd_Book font-normal text-base text-regularBlack leading-5 tracking-normal pb-4 pt-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus
                potenti ipsum malesuada faucibus malesuada quis at consequat
                gravida.{" "}
              </p>
              <a
                href="/contact-us"
                rel="noreferrer"
                className="bg-regularBlue border border-solid border-regularBlue text-center flex flex-row items-center justify-center h-16 rounded-[50px] font-CircularStd_Medium font-medium text-xs md:text-base lg:text-base text-regularWhite mx-6 lg:mx-0 px-12 md:px-24 lg:px-24"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default FAQ;
