import React from "react";
import { Link } from "react-router-dom";
import AppStore from "../../assets/images/appstore.png";
import MobilePreview from "../../assets/images/mobile-preview2.png";
import PlayStore from "../../assets/images/playstore.png";
import "./hero.scss";

function Hero(): JSX.Element {
  return (
    <section className="hero overflow-hidden" id="hero">
      <div className="container mx-auto mt-5 pt-5 pb-5 flex flex-col-reverse md:flex md:flex-col-reverse lg:mt-1 lg:pt-1 lg:flex lg:flex-row  md:items-center">
        {/* OMNEY DESCRIPTION */}
        <div className="w-4/5 lg:w-1/2 hero-text flex flex-col space-y-3 items-start text-start lg:flex lg:flex-col lg:space-y-6 lg:mx-auto lg:items-center lg:text-left">
          {/* SCHOOL BUS */}
          {/* <div className="w-1/2 animated block pb-4 lg:hidden">
            <img src={Bus} className="pl-3" alt="school bus" />
          </div> */}

          <h2 className="font-CircularStd_Bold font-bold text-regularWhite text-base text-left w-full md:text-center md:pt-8 text-inherit lg:w-3/4 lg:leading-10 lg:mt-0 lg:flex lg:flex-col lg:space-y-1 lg:items-start lg:pt-3">
            <span className="capitalize text-4xl md:text-6xl pt-4 md:pt-0 lg:pt-0 lg:text-6xl">
              Go Borderless
            </span>
            {/* <span className="capitalize lg:text-5xl">And Hold Over</span>
            <span className="capitalize lg:text-5xl">300+ Stocks</span> */}
          </h2>

          <p className="pt-4 text-regularWhite lg:w-10/12 font-medium  text-base font-CircularStd_Medium text-justify">
            Trade and Invest in a wide range of International Stocks, Bonds,
            ETFs and REITs. With as low as $10 you can build a portfolio of
            foreign assets and earn dividends in multiple currencies
          </p>

          <div className="flex flex-row items-center space-x-8 justify-start pt-5 lg:w-4/5">
            <Link to={`/`} className="cursor-pointer">
              <img
                src={AppStore}
                className="cursor-pointer"
                alt="apple_store"
              />
            </Link>

            <Link to={`/`} className="cursor-pointer">
              <img
                src={PlayStore}
                className="cursor-pointer"
                alt="apple_store"
              />
            </Link>
          </div>
        </div>

        {/* MOBILE PREVIEW */}
        <div className="lg:w-1/2 mx-auto mt-3 lg:pt-7 pb-5">
          <img
            src={MobilePreview}
            className="pl-3 animatedd lg:w-11/12"
            alt="school bus"
          />
        </div>
      </div>

      <div className="container mx-auto"></div>
    </section>
  );
}

export default Hero;
