/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import OmneyDarkLogo from "../../assets/svgs/logo-dark.svg";
import OmneyLogo from "../../assets/svgs/logo.svg";
import { contextProps, linkProps } from "../../utils/interfaces";
import "./header.scss";

function Header({ colorTheme }: contextProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [scrollTo, setScrollTo] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrollTo(true);
      } else if (window.scrollY < 20) {
        setScrollTo(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
  }, []);

  const Links: linkProps[] = [
    {
      id: 1,
      name: "Home",
      link: `/`,
    },
    { id: 2, name: "Learn", link: `#education` },
    // { id: 3, name: "FAQs", link: "/FAQ" },
    { id: 4, name: "Contact Us", link: "/contact-us" },
  ];

  return (
    <nav className={`container mx-auto sticky top-0 z-50`}>
      <div
        className={`font-CircularStd_Bold leading-6 font-bold text-regularWhite w-full lg:flex lg:flex-row lg:items-center lg:justify-between md:justify-around`}
      >
        <div className="md:py-8 lg:flex lg:flex-row lg:pt-5 lg:pb-3 lg:space-x-10">
          <Link to={"/"}>
            {colorTheme === "dark" ? (
              <img
                src={OmneyDarkLogo}
                alt="secure app logo"
                className="pt-5 pl-5 md:pt-0 md:pl-5 lg:pt-0 lg:pl-0"
              />
            ) : (
              <img
                src={OmneyLogo}
                alt="secure app logo"
                className="pt-5 pl-5 md:pt-0 md:pl-5 lg:pt-0 lg:pl-0"
              />
            )}
          </Link>

          <ul
            className={`lg:flex lg:flex-row lg:items-center text-regularWhite lg:pl-12 Top ${
              open
                ? `slide-from-top flex flex-col md:flex md:flex-col md:space-y-8 md:pt-10 z-1 absolute ${
                    colorTheme === "dark" ? "bg-regularWhite" : "bg-regularBlue"
                  } w-full h-screen transition-all duration-500 ease-in`
                : "hidden slide-from-bottom"
            }`}
          >
            <>
              {Links.map((link: linkProps, idx: any) => (
                <li
                  key={link.id + idx}
                  className={`md:ml-4 text-base lg:text-base md:text-xl md:my-0 my-2 px-4 md:px-14 lg:px-3 font-CircularStd_Bold ${
                    colorTheme === "dark"
                      ? "text-regularBlack"
                      : "text-regularWhite"
                  } leading-10 txt active:text-regularWhite`}
                >
                  <Link
                    to={link.link}
                    className={`${
                      colorTheme === "dark"
                        ? "text-regularBlack"
                        : "text-regularWhite"
                    }hover:text-regularWhite duration-500 active:text-regularWhite`}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
              {open && (
                <>
                  <li className="mt-10 px-2 md:px-14">
                    <Link
                      to={`/`}
                      className={`text-center ${
                        colorTheme === "dark"
                          ? "text-regularBlue"
                          : "text-regularWhite"
                      } font-CircularStd_Medium hover:text-regularWhite duration-500 w-28 rounded-[20px] ${
                        colorTheme === "dark"
                          ? "border border-solid border-regularBlue themeBg"
                          : "bg-regularBlue border border-solid border-regularWhite"
                      } no-underline text-base md:text-base px-12 py-4 md:px-12`}
                      rel="noreferrer"
                    >
                      Login
                    </Link>
                  </li>

                  <li className="mt-10 md:mt-8 px-2 md:px-14">
                    <Link
                      to={`/`}
                      className={`btn rounded-[20px] ${
                        colorTheme === "dark"
                          ? "border border-solid bg-regularBlue"
                          : "border border-solid border-regularWhite bg-regularWhite"
                      } px-10 text-center py-4 font-CircularStd_Medium font-medium text-base  leading-4 ${
                        colorTheme === "dark"
                          ? "text-regularWhite"
                          : "text-regularBlue"
                      } transition-all duration-500 ease-in no-underline w-28`}
                      rel="noreferrer"
                    >
                      Sign Up
                    </Link>
                  </li>
                </>
              )}
            </>
          </ul>
        </div>

        <div
          onClick={() => setOpen(!open)}
          className="text-3xl md:text-4xl md:pt-5 md:pr-8 absolute right-8 top-6 cursor-pointer md:block lg:hidden"
        >
          {open ? (
            <AiOutlineClose
              color={`${colorTheme === "dark" ? "#333333" : "#ffffff"}`}
            />
          ) : (
            <GiHamburgerMenu
              color={`${colorTheme === "dark" ? "#333333" : "#ffffff"}`}
            />
          )}
        </div>

        <div
          className={`md:hidden lg:flex lg:flex-row lg:items-center lg:space-x-8 ${
            open ? "hidden" : "hidden"
          }`}
        >
          <Link
            to={`/`}
            className={`${
              colorTheme === "dark" ? "text-regularBlue" : "text-regularWhite"
            } hover:text-regularWhite duration-500 no-underline font-CircularStd_Medium  font-medium rounded-[20px] ${
              colorTheme === "dark"
                ? "border border-solid border-regularBlue themeBg"
                : "bg-regularBlue border border-solid border-regularWhite"
            } px-4 py-2 w-28 text-center border-solid`}
            rel="noreferrer"
          >
            Sign In
          </Link>

          <Link
            to={`/`}
            className={`btn rounded-[20px] ${
              colorTheme === "dark"
                ? "border border-solid bg-regularBlue"
                : "border border-solid border-regularWhite bg-regularWhite"
            } px-3 text-center py-3 font-CircularStd_Medium font-medium text-base  leading-4 ${
              colorTheme === "dark" ? "text-regularWhite" : "text-regularBlue"
            } transition-all duration-500 ease-in no-underline w-28`}
            rel="noreferrer"
          >
            Sign Up
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Header;
