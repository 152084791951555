import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components";
import ContactUs from "./pages/ContactUs/ContactUs";
import FAQ from "./pages/FAQ/FAQ";
import Privacy from "./pages/Privacy/Privacy";
import TermsAndConds from "./pages/TermsAndConditions/TermsAndConds";

function App(): JSX.Element {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-and-conditions" element={<TermsAndConds />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/FAQ" element={<FAQ />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
