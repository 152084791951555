import React from "react";

function Security(): JSX.Element {
  return (
    <section className="secuirty overflow-hidden bg-regularWhite" id="secuirty">
      <div className="container mx-auto mt-5 lg:mt-16 pt-10 pb-5 flex flex-col items-baseline md:flex md:flex-col lg:flex lg:space-x-12 lg:flex-row-reverse lg:pt-0 lg:pb-5  md:items-center">
        {/* PUPILS */}
        <div
          className="lg:w-1/2 pt"
          data-aos="fade-down-right"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <img
            src={require("../../assets/gifs/security-pay.gif")}
            className="py-4 px-5 md:px-0 lg:px-0"
            alt=""
          />
        </div>

        {/* ABOUT */}
        <div
          className="w-full lg:w-1/2 flex flex-col items-center space-y-4 pt-8 lg:text-left lg:items-start"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <h2 className="text-regularBlack pb-4 lg:text-5xl lg:leading-9 font-bold font-CircularStd_Bold lg:w-full">
            Security
          </h2>

          <p className="w-4/5 text-justify lg:w-8/12 font-InterRegular font-normal text-base text-regularGrey lg:text-sm lg:leading-7 lg:text-left">
            We utilize encryption innovation in keeping your personal
            information safe, while your foreign assets are regulated by the
            Nigerian Exchange Group, Securities and Exchange Commission of
            Nigeria, and the U.S. Securities and Exchange Commission.
          </p>

          <button
            className="cursor-pointer border border-solid text-regularBlue font-CircularStd_Medium font-medium text-lg w-44 text-center px-8 py-4 rounded-[50px] border-regularBlue light-bg"
            type={"button"}
          >
            Learn More
          </button>
        </div>
      </div>
    </section>
  );
}

export default Security;
