import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header2";
import "./terms.scss";

function TermsAndConds(): JSX.Element {
  const { pathname }: any = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main className="overflow-hidden">
      <Header colorTheme={"dark"} />
      <section className="overflow-hidden">
        <div className="container mx-auto flex flex-col items-center justify-center pt-16">
          <article className="space-y-3 w-4/5 pb-14 mb-20">
            <div className="flex flex-col space-y-4 items-start justify-start pb-12">
              <h4 className="font-CircularStd_Bold  font-bold text-black text-3xl md:text-3xl lg:text-7xl">
                Terms & Conditions
              </h4>
              <span className="font-CircularStd_Book font-medium text-2xl text-black text-left">
                Last updated: November 01, 2022
              </span>

              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6 pt-12 pb-3">
                Omney application (Omney) provides a portal, or gateway, to the
                financial services offered by CSL Stockbrokers Limited ('CSLS'),
                and Affiliates.
              </span>

              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6 pb-3">
                The Site is operated by CSLS, a company regulated by the
                Securities & Exchange Commission and The Nigerian Stock
                Exchange. CSLS is one of the leading financial/brokerage
                services institutions in Nigeria, with the following operating
                companies: FCMB Asset Management Limited and CSL Capital (UK)
                Limited.
              </span>

              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                CSL Stockbrokers Limited is a private limited company registered
                in the Federal Republic of Nigeria under RC: No. 27567 with
                head-office at First City Plaza, 44 Marina Road, Lagos State,
                Nigeria
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                THE PROVISION OF FINANCIAL SERVICES OR PRODUCTS
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Omney and CSLS’s operation of the portal, or gateway, does not
                constitute an offer of any products or services provided by
                CSLS.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Not all the products and services that you may be able to view
                via the Site are available in all geographical locations. The
                relevant CSLS member reserves the right to make the final
                determination on whether or not you are eligible for any
                particular product or service. If you choose to enter a website
                outside your country of residence, you are advised that it may
                not be legal in that jurisdiction for you to access or use the
                facilities available on that site and the legal requirements of
                that jurisdiction may prohibit you from dealing or otherwise
                transacting in that jurisdiction.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                All products or services provided to you by CSLS or its
                Affiliates shall only be available in the jurisdiction/s within
                which the member providing the product or service is authorized
                to operate.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                You agree that any of the products or services provided to you
                by CSLS or its Affiliates shall be deemed to be provided in the
                jurisdiction within which the member providing the product or
                service is authorized to operate.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                <strong>CSLS</strong> hosts Omney application and provides
                technical support, access and links to the Local Sites of CSLS.
                Financial services and products may be obtained from Local
                Sites. The information contained on the Site is for information
                purposes only. Omney and CSLS do not hold themselves out as
                providing legal, financial or other advice via the Site.
                References to 'you', 'your' and 'yours' are references to the
                person(s) accessing the Site or any Local Site. References to
                'we', 'us' and 'our' are references to CSL Stockbrokers Limited.
                References to the 'CSLS’ are references to CSL Stockbrokers
                Limited, and its affiliates.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                LOCAL SITES
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                All products and services provided by CSLS are subject to the
                terms and conditions and disclaimers of the applicable agreement
                governing their supply and use ("Product terms and conditions").
                Where Product terms and conditions conflict with these Terms and
                Conditions or any Local Site's terms and conditions the Product
                terms and conditions shall prevail.
              </span>

              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                You may be linked to other CSLS websites or pages issued by
                affiliates of CSLS ("Local Sites") through the Site. Your use of
                any Local Site shall be governed by both these Terms and
                Conditions and the terms and conditions of the Local Site.
                However, where these Terms and Conditions conflict with those of
                the Local Site, the terms and conditions of the Local Site shall
                prevail.
              </span>

              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Please carefully read any Local Site and/or Product terms and
                conditions before using the Local Site or product.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                CHANGES
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                The information, material and content provided in the pages of
                the Site or any Local Site may be changed at any time without
                notice. Changes may be made to these Terms and Conditions by us
                at any time without notice to you by updating the posting of the
                Terms and Conditions.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                SECURITY
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                In certain circumstances you may be requested to register with
                Omney. When you register, you must provide accurate and complete
                information and up-date such information when necessary, so it
                remains accurate and complete. When you register and are
                accepted as a registered user of the Site, you choose a unique
                username and password. Please use maximum caution to keep your
                username and password confidential and log-off from the Site
                when your session is complete to prevent unauthorized access to
                your information. If your username or password is subject to
                unauthorized access, you should immediately inform CSLS and
                relevant Affiliates.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                DATA PROTECTION AND PRIVACY POLICIES
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                We are committed to protecting your privacy. All information
                gathered from you in connection with your use of the Site or any
                Local Site will be maintained in accordance with the Nigeria
                Data Protection Regulations and Global Data Protection
                Regulations.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                NO OFFER
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Information or opinions provided by us or through a third party
                on the Site or by a Local Site should not be used for investment
                advice and do not constitute an offer to sell or solicitation of
                an offer to buy any securities or financial instruments or any
                advice or recommendation with respect to such securities or
                other financial instruments. When deciding about your
                investments, you should seek the advice of a professional
                financial adviser.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                NO WARRANTIES
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                The content of the Site and the Local Sites are distributed to
                you without any warranty of any kind. We and Third Party content
                providers disclaim ant and all warranties.
              </span>

              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Information on the Site and any Local Site is provided on an "AS
                IS", "AS AVAILABLE" basis and to the fullest extent permitted by
                law, we do not give our make any warranty or representation of
                any kind, whether express or implied in respect of such
                information whether in respect of the Site or any Local Site.
                Your use of the Site or any Local Site us at your sole risk.
              </span>

              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                We do not warrant the accuracy, adequacy, completeness or
                timeliness of the information, material, products and services
                or the error-free use of the Site of Local Site.{" "}
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                We do not represent or warrant that the Site or any Local Suite
                will be available or that it will meet your requirements, that
                access will be uninterrupted, that there will be no delays,
                failures, errors or omissions or loss of transmitted
                information, that no viruses or other contaminating or
                destructive properties will be transmitted or that no damage
                will occur to your computer system. You have sole responsibility
                for adequate protection and back up of data and/or equipment and
                for undertaking reasonable and appropriate precautions to scan
                for computer viruses or other destructive properties.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                We make no representations or warranties regarding the accuracy,
                functionality or performance of any Third-Party software that
                may be used in connection with the Site or any Local Site.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                LIMITATION OF LIABILITY
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                In no event shall we be liable for any damages, losses or
                liabilities including without limitation, director or indirect,
                special, incidental, consequential damages, losses or
                liabilities, in connection with your use of the Site or your
                reliance on or use or use or inability to use the information,
                materials, products and services on the Site or in connection
                with any failure or performance, error, omission, interruption,
                defect, delay in operation or transmission, computer virus or
                line or system or system failure, even if you advise of the
                possibility of such damages, losses or expenses.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                LINKS TO THIRD PARTY SITES OR CONTENT
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Your use of hyperlinks on the Site is at your own risk. We
                provide hyperlinks to other locations on the Internet for
                information and convenience purposes only. No endorsement of
                third-party websites or content is implied. We are not
                responsible for the content of any other websites or pages
                linked to or from the Site or any Local Site. We have not
                verified the content of any such websites, or pages. It is
                recommended that you view the linked website's terms or privacy
                policy pages to understand how use of that website may affect
                you.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                We are not responsible for the accuracy, timeliness or the
                continued availability or the existence of content, hyperlinks,
                or third-party websites or pages linked to the Site or any Local
                Site. Links to downloadable software sites are for convenience
                only and we are not responsible or liable for any difficulties
                or consequences associated with downloading the software. Use of
                any downloaded software is governed by the terms of the licence
                agreement, if any, which accompanies or is provided with the
                software.
              </span>{" "}
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                {" "}
                The Site may offer access to news services, market analysis,
                financial planning tools or other information provided by third
                parties on the Site or any Local Site. No endorsement or
                approval of any third parties or their advice, opinions,
                information, products or services is expressed or implied by any
                information on the Site or any Local Site. We do not control,
                cannot guarantee, and are not responsible for the accuracy,
                timeliness or even the continued availability or existence of
                such content.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                INTERNET E-MAIL
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Do not use ordinary e-mail messages to communicate personal or
                confidential information to us. Use the secure server available
                at your Local Site. Ordinary e-mail messages sent over the
                Internet may be intercepted, lost or altered. We are not
                responsible for them and will not be liable to you or anyone
                else for any damages in connection with any messages sent by you
                to us using ordinary e-mail.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                EMAIL ALERT SERVICE
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                We may, at our discretion, provide an email alert service (the
                "Service") allowing you to receive alerts relating to CSLS
                and/or such other parties or matters as we may include from time
                to time.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Your use of the Service will result in us holding the data you
                submit for use of the Service. The data will be held in the
                Federal Republic of Nigeria and will only be used to provide you
                with the information you have requested receiving via the
                Service or for statistical analysis. By using the Service, you
                agree to us holding and using your data as indicated above.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                We may at any time at our discretion withdraw the Service and/or
                your use of it. The provisions under "No Warranties" in these
                Terms and Conditions shall apply as if reference to "the Site"
                includes reference to the Service.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                TRADEMARKS AND COPYRIGHT
              </p>

              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Copyright in the pages, screens, information, and all material
                in their arrangement, included in the Site or any Local Site is
                owned by or licensed to CSLS, unless otherwise noted. You may
                imprint, copy download or temporarily store extracts from the
                Site or any Local Site for your personal information or when you
                use any products and services of any Local Site. You may not
                alter or otherwise make any changes to any material that you
                print or download from the Site or any Local Site, including,
                without limitation, removing any identifying marks or legends
                from such material. You may not distribute these materials to
                others. Any other use is prohibited unless you first request and
                obtain our written permission. In particular no one may use a
                part of the Site or any Local Site on any other website, or link
                any other website to the Site or any Local Site, without our
                prior written permission or the consent of CSLS or its relevant
                affiliates.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Content from a third party provider available on or through the
                Site or any Local Site may not be duplicated, distributed,
                published, transferred, transmitted, copied, altered, sold, used
                to create derivative works or otherwise misused. You must comply
                with all terms disclosed to you as required from time to time by
                any third party supplier of data or services to the Site or any
                Local Site, including but not limited to, where necessary,
                entering into a direct agreement with such third party in
                respect of your use of their data.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                You shall not use the Site or any Local Site for any purpose
                which is unlawful, abusive, libelous, obscene, or threatening.
              </span>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                <strong>CSLS</strong> is a trademark of{" "}
                <strong>CSL Stockbrokers Limited</strong>
                and all rights in and to CSLS vest in CSL Stockbrokers Limited.
                Other than as provided above you may not use or reproduce the
                CSLS trademark, logo or brand name.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                NOT INTENDED FOR CHILDREN
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                The Site is not intended for use by minors.
              </span>
            </div>

            <div className="flex flex-col w-5/6 items-start justify-start space-y-5 py-7">
              <p className="text-black text-left font-CircularStd_Bold text-2xl">
                GOVERNING LAW
              </p>
              <span className="font-CircularStd_Book font-normal text-black opacity-100 leading-6">
                Your use of the Site is governed by Nigerian law.
              </span>
            </div>
          </article>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default TermsAndConds;
