import React from "react";
import AppStore from "../../assets/images/appstore.png";
import PlayStore from "../../assets/images/playstore.png";
import "./getStarted.scss";

function GetStarted(): JSX.Element {
  return (
    <section className="get-started overflow-hidden flex flex-col items-center justify-center bg-regularWhite ">
      <div className="container mx-auto w-4/5 hidden md:hidden lg:block transformit h-auto mt-10 mb-10 relative z-50 img-bg">
        <div className="-z-50">
          <div className="flex flex-col items-baseline content-center justify-center space-y-4 py-24 pl-20 absoluter top-16r w-full z-0">
            <h2 className="font-CircularStd_Bold text-regularBlack font-bold text-5xl mt-9 w-1/2">
              Get Started Now!{" "}
            </h2>

            {/* <p className="font-CircularStd_Medium font-medium text-regularBlack text-base w-1/2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus
              potenti ipsum malesuada faucibus malesuada quis at consequat
              gravida.{" "}
            </p> */}

            <div className="flex flex-row items-center space-x-8 justify-start pt-5 lg:w-4/5">
              <a href={`/`} className="cursor-pointer">
                <img
                  src={AppStore}
                  className="cursor-pointer"
                  alt="apple_store"
                />
              </a>

              <a href={`/`} className="cursor-pointer">
                <img
                  src={PlayStore}
                  className="cursor-pointer"
                  alt="apple_store"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto w-11/12 text-center flex flex-row items-center justify-center  lg:hidden h-auto mt-5 mb-10 z-50 bg-lightBlue rounded-[20px]">
        <div className="flex flex-col items-center justify-center space-y-4 w-4/5 py-8 lg:py-0">
          <h2 className="font-CircularStd_Bold text-regularBlack font-bold text-xl w-full text-center mx-auto">
            Get Started Now!{" "}
          </h2>

          <div className="flex flex-row items-center space-x-3 justify-center pt-5 w-full lg:hidden">
            <a href={`/`} className="cursor-pointer">
              <img
                src={AppStore}
                className="cursor-pointer"
                alt="apple_store"
              />
            </a>

            <a href={`/`} className="cursor-pointer">
              <img
                src={PlayStore}
                className="cursor-pointer"
                alt="apple_store"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetStarted;
