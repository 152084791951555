import React from "react";
import RealTime from "../../assets/images/real-time.png";

function RealTimeTrading(): JSX.Element {
  return (
    <section
      className="real-time bg-regularWhite overflow-hidden"
      id="real-time-trading"
    >
      <div className="container mx-auto mt-5 lg:mt-16 pt-10 pb-5 flex flex-col items-baseline md:flex md:flex-col lg:flex lg:space-x-12 lg:flex-row-reverse lg:pt-0 lg:pb-5  md:items-center">
        {/* PUPILS */}
        <div
          className="lg:w-1/2 pt"
          data-aos="fade-down-right"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <img src={RealTime} className="py-4 px-5 md:px-0 lg:px-0" alt="" />
        </div>

        {/* ABOUT */}
        <div
          className="w-full lg:w-1/2 flex flex-col items-center space-y-4 pt-8 lg:text-left lg:items-start"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <h2 className="text-regularBlack pb-4 lg:text-5xl lg:leading-9 font-bold font-CircularStd_Bold lg:w-full">
            Real Time Trading
          </h2>

          <p className="w-4/5 text-justify lg:w-8/12 font-InterRegular font-normal text-base text-regularGrey lg:text-sm lg:leading-7 lg:text-left">
            Trade smarter with real-time news updates and analyze opportunities
            with innovative trading tools.
          </p>

          <button
            className="cursor-pointer border border-solid text-regularBlue font-CircularStd_Medium font-medium text-lg w-44 text-center px-8 py-4 rounded-[50px] border-regularBlue light-bg"
            type={"button"}
          >
            Learn More
          </button>
        </div>
      </div>
    </section>
  );
}

export default RealTimeTrading;
